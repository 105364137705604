.layout-topbar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 4rem;
  transition: width var(--p-transition-duration);
  display: flex;
  color: var(--p-primary-contrast-color);
  background-color: var(--p-primary-color);
  box-shadow: 0px 1px 10px rgb(0 0 0 / 12%), 0px 4px 5px rgb(0 0 0 / 14%),
    0px 2px 4px -1px rgb(0 0 0 / 20%);

  --p-megamenu-item-focus-background: rgba(255, 255, 255, 0.12);
  --p-megamenu-item-active-background: rgba(255, 255, 255, 0.12);

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .layout-topbar-mobile-button {
    color: var(--p-primary-contrast-color) !important;
  }

  .layout-topbar-start {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 17rem;
    background-color: var(--p-primary-color);

    .layout-topbar-logo {
      display: flex;
      align-items: center;
      justify-content: center;

      .layout-topbar-logo-slim {
        display: none;
      }
    }

    .layout-menu-button {
      position: absolute;
      top: 50%;
      margin-top: -1.25rem;
      right: -1.25rem;
      width: 2.5rem;
      height: 2.5rem;
      display: inline-flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      color: var(--p-primary-contrast-color);
      background-color: var(--p-surface-900);
      box-shadow: 0px 3px 5px rgb(0 0 0 / 6%), 0px 7px 9px rgb(0 0 0 / 12%),
        0px 20px 25px -8px rgb(0 0 0 / 18%);
      transition: background-color var(--p-transition-duration);
      cursor: pointer;

      &:hover {
        background-color: var(--p-secondary-500);
      }

      i {
        transition: transform var(--p-transition-duration);
      }
    }

    .layout-topbar-mobile-button {
      display: none;
      border-radius: 50%;
      width: 2.5rem;
      height: 2.5rem;
      cursor: pointer;
    }
  }

  .layout-topbar-end {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem;

    .layout-megamenu {
      background: none;

      &.p-megamenu.p-megamenu-horizontal
        .p-megamenu-root-list
        > .p-megamenu-item
        > .p-megamenu-item-content
        .p-megamenu-item-link {
        .p-megamenu-item-label,
        .p-megamenu-item-icon,
        .p-submenu-icon {
          color: var(--p-primary-contrast-color);
        }
      }
    }

    .layout-topbar-actions-end {
      .layout-topbar-items {
        display: flex;
        flex-direction: row;
        gap: 1rem;

        > li {
          position: relative;

          > a {
            width: 2.5rem;
            height: 2.5rem;
            user-select: none;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            color: var(--p-primary-contrast-color);

            i {
              font-size: 1.25rem;
            }

            &:hover {
              background: rgba(255, 255, 255, 0.12);
              transition: background-color var(--p-transition-duration);
            }
          }

          > div {
            position: absolute;
            top: 3.25rem;
            right: 0;
            min-width: 20rem;
            padding: 1rem 0;
            transform-origin: top;
            background: var(--p-surface-0);
            color: var(--p-text-color);
            border-radius: var(--p-border-radius-md);
            box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
              0 8px 10px 1px rgba(0, 0, 0, 0.14),
              0 3px 14px 2px rgba(0, 0, 0, 0.12);
          }

          &.layout-topbar-search {
            position: static;

            .layout-search-panel {
              background: var(--p-surface-0);
              height: 4rem;
              width: 100%;
              position: absolute;
              top: 0;
              left: 0;
              z-index: 1000;
              transform-origin: top;
              display: flex;
              align-items: center;
              padding: 0 2rem;
              border-radius: 0;

              > i {
                color: var(--p-text-color);
              }

              input {
                border-radius: 0;
                border: 0 none;
                flex-grow: 1;
                background-color: transparent;
                margin: 0 1rem;

                &:focus {
                  outline: 0 none;
                }
              }
            }
          }
        }
      }
    }
  }
}
