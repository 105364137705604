/**
* Editor
*/
eva-editor.red {
  .editor-label {
    color: var(--c-color1) !important;
    border-color: var(--c-color1) !important;
  }
  .p-editor-toolbar.ql-snow,
  .p-editor-content.ql-snow {
    border-color: var(--c-color1) !important;
  }
}

/**
* Inplace
*/
.p-inplace .p-inplace-content {
  display: flex !important;
  .p-button {
    width: 3rem;
    padding: 0.84rem 0;
  }
}

/**
* Tabs
*/
.p-tabs {
  .p-tablist {
    p-tab {
      &:not(:first-of-type) {
        border-left: 1px solid var(--p-tabs-tablist-border-color) !important;
      }

      span {
        &:before,
        &:after {
          font-family: 'primeicons', 'Roboto';
          margin-right: 0.5rem;
          margin-left: 0.5rem;
        }
      }

      // Project tabs
      .project-data-tab:before {
        content: '\e925';
      }

      // User
      .project-persons-tab:before {
        content: '\e939';
      }

      // Flächen
      .project-sites-tab:before {
        content: '\e918';
      }

      // Bilder
      .project-images-tab:before {
        content: '\e973';
      }

      // Zertifizierungen
      .project-certifications-tab:before {
        content: '\e937';
      }

      // Certification tabs
      .certification-data-tab:before {
        content: '\e925';
      }

      .certification-sites-tab:after {
        content: '\e9c2';
      }

      .certification-baseline-tab:after {
        content: '\e96d';
      }

      .certification-additionality-tab:after {
        content: '\e90e';
      }

      .certification-growthmodel-tab:after {
        content: '\e98b';
      }

      .certification-wizard-tab:after {
        content: '\e90a';
      }

      .certification-documents-tab:after {
        content: '\e98d';
      }

      .certification-documents-overview-tab:after {
        content: '\e967';
      }
    }
  }
}
