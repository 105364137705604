html {
  height: 100%;
  font-size: var(--eva-font-scale);
}

body {
  background: var(--p-surface-0);
  color: var(--p-primary-color);
  font-weight: 400;
  font-family: var(--eva-font-family);
  margin: 0;
  min-height: 100%;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: var(--p-primary-color);

  &:hover {
    color: var(--p-primary-300);
  }
}

.p-toast {
  &.p-toast-top-right,
  &.p-toast-top-left,
  &.p-toast-top-center {
    top: 85px;
  }
}
