.layout-container {
  &.layout-menu-profile-active {
    .layout-menu-profile-toggler {
      transform: rotate(-180deg);
    }
  }
}

.layout-menu-profile {
  width: 100%;
  background: var(--p-surface-100);
  overflow: hidden;

  > button {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 0.75rem 1.5rem;
    color: var(--p-text-color);
    transition: background-color var(--p-transition-duration);

    > span {
      display: flex;
      flex-direction: column;
      margin-left: 0.5rem;
    }

    > i {
      margin-left: auto;
      transition: transform var(--p-transition-duration);
    }

    &:hover {
      background-color: var(--p-navigation-item-focus-background);
    }
  }

  > ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    > li {
      > button {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0.75rem 1.5rem;
        transition: background-color var(--p-transition-duration),
          box-shadow var(--p-transition-duration);
        color: var(--p-text-color);

        > i {
          margin-right: 0.5rem;
          color: var(--p-text-color);
        }

        &:hover {
          background-color: var(--p-navigation-item-focus-background);
        }
      }
    }
  }
}
