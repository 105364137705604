// loading flex and icons from primeng
@import 'node_modules/primeflex/primeflex.scss';
@import 'node_modules/primeicons/primeicons.css';

// loading fonts
@import './fonts/_fonts';

// loading layout
@import './layout/layout.scss';

// loading components
@import './components/_components.scss';

:root {
  --eva-font-family: Roboto, 'Helvetica Neue Light', 'Helvetica Neue', Helvetica,
    Arial, 'Lucida Grande', sans-serif;
  --eva-font-scale: 14px;
}

.eva-theme {
  // Text Color
  --p-text-color: var(--p-surface-900);

  // Secondary Color
  --p-secondary-color: var(--p-secondary-500);
  --p-secondary-contrast-color: #ffffff;

  // Form Field
  --p-form-field-padding-y: 0.643rem;

  // Overlay
  --p-overlay-modal-padding: 1.25rem;

  // Navigation
  --p-navigation-item-focus-background: rgba(255, 255, 255, 0.12);
  --p-navigation-item-active-background: rgba(255, 255, 255, 0.24);
  --p-navigation-item-padding: 1rem 0.75rem;

  // Mega Menu
  --p-megamenu-item-focus-background: var(--p-surface-100);

  // Button
  --p-button-icon-only-width: 2.75rem;
  --p-button-secondary-background: var(--p-secondary-500);
  --p-button-secondary-border-color: var(--p-secondary-500);
  --p-button-secondary-hover-background: var(--p-secondary-300);
  --p-button-secondary-hover-border-color: var(--p-secondary-300);
  --p-button-secondary-active-background: var(--p-secondary-700);
  --p-button-secondary-active-border-color: var(--p-secondary-700);
  --p-button-secondary-color: var(--p-secondary-contrast-color);
  --p-button-secondary-hover-color: var(--p-secondary-contrast-color);
  --p-button-secondary-active-color: var(--p-secondary-contrast-color);
  --p-button-secondary-focus-ring-color: var(--p-secondary-600);
  --p-button-outlined-secondary-hover-background: var(--p-secondary-50);
  --p-button-outlined-secondary-active-background: var(--p-secondary-100);
  --p-button-outlined-secondary-border-color: var(--p-secondary-500);
  --p-button-outlined-secondary-color: var(--p-secondary-500);
  --p-button-text-secondary-color: var(--p-secondary-500);

  // Tavs
  --p-tabs-tab-hover-border-color: var(--p-primary-500);
  --p-tabs-tab-font-weight: 500;

  // Divider
  --p-divider-horizontal-margin: 0.5rem 0;

  // Tooltip
  --p-tooltip-background: var(--p-surface-0);
  --p-tooltip-color: var(--p-primary-500);
  --p-tooltip-padding: 0.6rem 0.75rem;

  --p-badge-border-radius: 20px;
  --p-datatable-body-cell-padding: 0.75rem;

  // Toast
  --p-toast-summary-font-weight: 700;
  --p-toast-detail-font-size: 1rem;
  --p-toast-text-gap: 0.25rem;

  --p-toast-close-button-width: 1.5rem;
  --p-toast-close-button-height: 1.5rem;
  --p-toast-close-icon-size: 1.5rem;

  --p-toast-success-background: var(--p-green-100);
  --p-toast-success-border-color: var(--p-green-800);
  --p-toast-success-color: var(--p-green-800);
  --p-toast-success-detail-color: var(--p-green-800);
  --p-toast-success-close-button-hover-background: var(--p-green-100);

  --p-toast-warn-background: var(--p-yellow-200);
  --p-toast-warn-border-color: var(--p-yellow-800);
  --p-toast-warn-color: var(--p-yellow-800);
  --p-toast-warn-detail-color: var(--p-yellow-800);
  --p-toast-warn-close-button-hover-background: var(--p-yellow-200);

  --p-toast-error-background: var(--p-red-100);
  --p-toast-error-border-color: var(--p-red-600);
  --p-toast-error-color: var(--p-red-600);
  --p-toast-error-detail-color: var(--p-red-600);
  --p-toast-error-close-button-hover-background: var(--p-red-100);

  --p-toast-info-background: var(--p-blue-100);
  --p-toast-info-border-color: var(--p-blue-600);
  --p-toast-info-color: var(--p-blue-600);
  --p-toast-info-detail-color: var(--p-blue-600);
  --p-toast-info-close-button-hover-background: var(--p-blue-100);

  // Dialog
  --p-dialog-content-padding: var(--p-overlay-modal-padding)
    var(--p-overlay-modal-padding) var(--p-overlay-modal-padding)
    var(--p-overlay-modal-padding);
  --p-dialog-footer-padding: 0.75rem var(--p-overlay-modal-padding);
  --p-dialog-title-font-size: 1.2rem;

  // Progress Spinnter
  --p-progressspinner-color-1: var(--p-secondary-color);
  --p-progressspinner-color-2: var(--p-secondary-color);
  --p-progressspinner-color-3: var(--p-secondary-color);
  --p-progressspinner-color-4: var(--p-secondary-color);

  // ListBox
  --p-listbox-list-padding: 0;
  --p-listbox-option-padding: 1rem 0.75rem;

  // ToggleButton
  --p-togglebutton-padding: 0.8rem 1rem;
  --p-togglebutton-content-checked-background: var(--p-secondary-color);
  --p-togglebutton-background: transparent;
  --p-togglebutton-checked-color: var(--p-secondary-contrast-color);
}
