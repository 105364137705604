.p-datatable-thead {
  .p-iconfield {
    width: 100%;
    input {
      width: 100%;
    }
  }

  p-columnfilterformelement {
    width: 100%;

    & > input {
      width: 100%;
    }
  }

  p-select,
  p-multiselect {
    width: 100%;
  }
}

.p-datatable-gridlines {
  .p-datatable-header {
    border-width: 1px !important;
  }
}

.p-datatable {
  .p-sortable-column {
    outline: 0 none;
    white-space: nowrap;
  }
  .p-datatable-header:empty,
  .p-datatable-footer:empty {
    padding: 0rem !important;
    border: 0;
  }
  .p-datatable-tbody {
    > tr {
      outline: 0 none;
    }
  }
}
