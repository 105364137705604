.p-togglebutton {
  &.p-togglebutton-checked:disabled {
    color: var(--p-togglebutton-disabled-background);
  }

  .p-togglebutton-content {
    & > div {
      display: flex;
    }
  }
}
