.verification-status {
  &.C,
  &.COMPLIANT {
    &:not(.border) {
      background-color: var(--c-color1) !important;
      color: white !important;
    }
    &.border {
      border-color: var(--c-color1) !important;
    }
  }

  &.CAR,
  &.CORRECTIVE_ACTION_REQUEST {
    &:not(.border) {
      background-color: var(--car-color1) !important;
      color: white !important;
    }
    &.border {
      border-color: var(--car-color1) !important;
    }
  }

  &.FAR,
  &.FORWARD_ACTION_REQUEST {
    &:not(.border) {
      background-color: var(--far-color1) !important;
      color: white !important;
    }
    &.border {
      border-color: var(--far-color1) !important;
    }
  }

  &.CL,
  &.CLARIFICATION_REQUEST {
    &:not(.border) {
      background-color: var(--cl-color1) !important;
      color: white !important;
    }
    &.border {
      border-color: var(--cl-color1) !important;
    }
  }

  &.E,
  &.ENDANGERED {
    &:not(.border) {
      background-color: var(--e-color1) !important;
      color: white !important;
    }
    &.border {
      border-color: var(--e-color1);
    }
  }

  &.NC,
  &.NON_COMPLIANT {
    &:not(.border) {
      background-color: var(--nc-color1) !important;
      color: white !important;
    }
    &.border {
      border-color: var(--nc-color1);
    }
  }
}
