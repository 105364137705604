.p-button:disabled {
  background-color: #0000001f !important;
  border-color: transparent;
  color: #00000061 !important;
  opacity: 1;
}

.p-button-icon-only:disabled {
  background-color: transparent !important;
}
