/* Layout */
@import 'libs/ng/base/scss/theme';

@import './overrides';
@import './components/verification-status';

// Third party libraries
@import '~maplibre-gl/dist/maplibre-gl.css';

@media print {
  html,
  body {
    font-size: 14px !important;
  }
}

.app-theme {
  --color-3-turkis: rgb(0, 60, 65);

  //   --accent-color: #{$accentColor}; PDD

  --error-red: var(--p-red-500);

  $color-2-green: rgb(50, 70, 0);
  --color-2-green: #{$color-2-green};
  --color-2-green-light: rgb(170, 180, 20);

  --transparent-value: 0.1;
  --far-color1: rgb(8, 145, 139, 1);
  --cl-color1: rgb(0, 98, 139, 1);
  --nc-color1: rgb(94, 22, 54, 1);
  --car-color1: rgb(150, 110, 0, 1);
  --c-color1: rgb(183, 186, 0);
  --e-color1: rgb(255, 39, 75);

  --far-color2: rgba(8, 145, 138, var(--transparent-value));
  --cl-color2: rgb(0, 98, 139, var(--transparent-value));
  --nc-color2: rgb(94, 22, 54, var(--transparent-value));
  --car-color2: rgb(150, 110, 0, var(--transparent-value));
  --c-color2: rgb(183, 186, 0, var(--transparent-value));

  --topbar-height: 4rem;
}

/*** generic classes ***/

.red {
  color: var(--p-red-700);
}

.green {
  color: var(--p-secondary-color);
}

.dark-blue {
  color: var(--color-3-turkis);
}

.white-space-pre-line {
  white-space: pre-line;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/** avoid too long lines */
.max-text-width {
  max-width: 60rem;
}

.pseudo-link {
  cursor: pointer;
  color: var(--p-primary-color);
  text-decoration: underline;
  &:focus {
    outline: none;
  }
}

/*** primeng class styles ***/
.cy-tree-percent-input .p-inputnumber-input {
  font-weight: bold;
  max-width: 4rem;
}

.card {
  border-radius: 0 !important;
}

.p-datatable-table {
  border-collapse: collapse !important;
}

// cancel button TODO!
.p-fileupload .p-fileupload-buttonbar {
  display: flex;
  gap: 0.25rem;
}

eva-file-upload p-fileupload p-progressbar .p-progressbar {
  display: none !important;
}

.p-fileupload-choose {
  max-width: fit-content;
}
.p-fileupload .p-fileupload-buttonbar p-button:last-child .p-button {
  background-color: grey;
}

.layout-topbar {
  min-height: var(--topbar-height);

  .layout-topbar-left {
    height: var(--topbar-height) !important;

    padding-right: 2rem;

    .layout-menu-button .pi {
      font-size: 2.2rem;
    }

    .layout-topbar-logo {
      justify-content: left;
    }
  }

  .layout-topbar-actions-right {
    display: flex;

    .topbar-info-text {
      flex: 3;
      justify-content: flex-end;
      display: flex;
      align-items: center;
      white-space: break-spaces;
    }

    ul.layout-topbar-items {
      flex: 1;
    }
  }

  .pi-pencil {
    color: var(--p-primary-color);
  }
}

.p-multiselect-chip .p-multiselect-label {
  flex-wrap: wrap !important;
  padding-bottom: 0 !important;
  .p-multiselect-token {
    margin-bottom: 0.5rem;
  }
}
.cdk-drag-preview {
  z-index: 5000 !important;
  position: relative;
}

/****** map related ***********/

mgl-map {
  height: 600px;
}

/** mapbox **/
.mapboxgl-popup-content {
  font-size: 1rem;
  padding: 1rem 1.5rem;
}

.mapboxgl-popup-close-button {
  position: absolute;
  right: -10px;
  top: -10px;
  border: 0;
  cursor: pointer;
  background-color: #fff;
  color: var(--p-primary-color);
  font-size: 19px;
  border-radius: 20px;
  border: 1px solid var(--p-primary-color);

  &:hover {
    background-color: #fff;
  }
}

.map-type-switch {
  position: absolute;
  z-index: 100;
  margin-left: 0.6rem;
  margin-top: 0.6rem;

  .p-dropdown-label {
    padding: 0.5rem !important;
  }
}

.mapbox-gl-draw_polygon {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"><path d="m15 12.3v-4.6c.6-.3 1-1 1-1.7 0-1.1-.9-2-2-2-.7 0-1.4.4-1.7 1h-4.6c-.3-.6-1-1-1.7-1-1.1 0-2 .9-2 2 0 .7.4 1.4 1 1.7v4.6c-.6.3-1 1-1 1.7 0 1.1.9 2 2 2 .7 0 1.4-.4 1.7-1h4.6c.3.6 1 1 1.7 1 1.1 0 2-.9 2-2 0-.7-.4-1.4-1-1.7zm-8-.3v-4l1-1h4l1 1v4l-1 1h-4z"/></svg>');
}

.mapbox-gl-draw_trash {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"><path d="M10,3.4 c-0.8,0-1.5,0.5-1.8,1.2H5l-1,1v1h12v-1l-1-1h-3.2C11.5,3.9,10.8,3.4,10,3.4z M5,8v7c0,1,1,2,2,2h6c1,0,2-1,2-2V8h-2v5.5h-1.5V8h-3 v5.5H7V8H5z"/></svg>');
}

.mapbox-gl-draw_combine {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"><path d="M12.1,2c-0.3,0-0.5,0.1-0.7,0.3l-1,1c-0.4,0.4-0.4,1,0,1.4l4.9,4.9c0.4,0.4,1,0.4,1.4,0l1-1 c0.4-0.4,0.4-1,0-1.4l-4.9-4.9C12.6,2.1,12.3,2,12.1,2z M8,8C7,8,7,9,7.5,9.5c0.3,0.3,1,1,1,1l-1,1c0,0-0.5,0.5,0,1s1,0,1,0l1-1l1,1 C11,13,12,13,12,12V8H8z M4,10c-0.3,0-0.5,0.1-0.7,0.3l-1,1c-0.4,0.4-0.4,1,0,1.4l1,1c0.4,0.4,1,0.4,1.4,0l1-1c0.4-0.4,0.4-1,0-1.4 l-1-1C4.5,10.1,4.3,10,4,10z M8,14c-0.3,0-0.5,0.1-0.7,0.3l-1,1c-0.4,0.4-0.4,1,0,1.4l1,1c0.4,0.4,1,0.4,1.4,0l1-1 c0.4-0.4,0.4-1,0-1.4l-1-1C8.5,14.1,8.3,14,8,14z"/></svg>');
}

.mapbox-gl-draw_uncombine {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"><path d="m12 2c-.3 0-.5.1-.7.3l-1 1c-.4.4-.4 1 0 1.4l1 1c.4.4 1 .4 1.4 0l1-1c.4-.4.4-1 0-1.4l-1-1c-.2-.2-.4-.3-.7-.3zm4 4c-.3 0-.5.1-.7.3l-1 1c-.4.4-.4 1 0 1.4l1 1c.4.4 1 .4 1.4 0l1-1c.4-.4.4-1 0-1.4l-1-1c-.2-.2-.4-.3-.7-.3zm-7 1c-1 0-1 1-.5 1.5.3.3 1 1 1 1l-1 1s-.5.5 0 1 1 0 1 0l1-1 1 1c.5.5 1.5.5 1.5-.5v-4zm-5 3c-.3 0-.5.1-.7.3l-1 1c-.4.4-.4 1 0 1.4l4.9 4.9c.4.4 1 .4 1.4 0l1-1c.4-.4.4-1 0-1.4l-4.9-4.9c-.1-.2-.4-.3-.7-.3z"/></svg>');
}

.mapbox-gl-draw_ctrl-draw-btn {
  background-repeat: no-repeat;
  background-position: center;
}

.content-component {
  height: 100%;
  padding: 0rem;
}

.hidden-hint {
  color: var(--surface-0);

  &.invalid {
    color: var(--p-red-500);
  }
}

p.invalid {
  color: var(--p-red-500);
}

/**
* RTE Editor
*/
.ql-editor p {
  font-size: 1.1rem;
}

.eva-info-grid {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  height: 100%;
  padding: 0 1.6rem;

  &.stand-alone {
    border: 1px solid lightgrey;
    background-color: white;
  }
  & .data-field {
    &.header-only {
      padding-bottom: 0;
      margin-bottom: -1.3rem;
      border-bottom: 0px;
    }

    &:not(.data-field:last-of-type):not(.header-only) {
      border-bottom: 1px solid lightgray;
    }

    padding: 1rem;
    overflow: hidden;

    > .header {
      color: var(--p-primary-color);
      font-size: 1rem;
      line-height: 1.5rem;
      text-transform: uppercase;
      > i.pi {
        margin-right: 0.3rem;
      }
    }

    > .data {
      > .eva-info-grid {
        height: unset;
      }
      .card {
        box-shadow: none !important;
      }
    }
  }
}

.layout-sidebar {
  z-index: 999;
}
.layout-sidebar .layout-menu .layout-root-menuitem.active-menuitem > a {
  display: flex;
}
.layout-content {
  margin-left: unset !important;
}
.layout-content-wrapper .layout-content:not(.content-padding) {
  padding: 0;
}
