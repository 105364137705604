.p-toast {
  --p-button-text-primary-hover-background: transparent;
  --p-button-primary-hover-border-color: transparent;

  .p-button {
    display: contents;
  }

  .p-toast-message-icon {
    margin-top: 0.15rem;
  }

  .p-toast-message-success .p-toast-close-button {
    color: var(--p-toast-success-color);
  }

  .p-toast-message-error .p-toast-close-button {
    color: var(--p-toast-error-color);
  }

  .p-toast-message-warn .p-toast-close-button {
    color: var(--p-toast-warn-color);
  }

  .p-toast-message-info .p-toast-close-button {
    color: var(--p-toast-info-color);
  }
}

.p-toast.p-toast-top-center {
  top: 85px !important;
}
