.p-tabs {
  .p-tablist {
    .p-tab {
      &:not(:first-of-type) {
        border-left: 1px solid var(--p-tabs-tablist-border-color) !important;
      }
    }

    .p-tab:not(:disabled) {
      cursor: pointer;
    }
  }
}
