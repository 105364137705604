.p-dialog {
  border: none !important;

  @media screen and (max-width: 640px) {
    max-height: 100% !important;
    max-width: 100% !important;
    width: 100% !important;
    height: 100% !important;
  }

  .p-dialog-header {
    border-bottom: var(--p-primary-color);
    background: var(--p-primary-color);
    color: var(--p-primary-contrast-color);
    padding: 0.75rem var(--p-overlay-modal-padding);
    border-top-left-radius: var(--p-border-radius-md);
    border-top-right-radius: var(--p-border-radius-md);

    button {
      --p-button-text-secondary-color: var(--p-primary-contrast-color);

      &:hover {
        --p-button-text-secondary-hover-background: rgba(255, 255, 255, 0.12);
      }

      &:active {
        --p-button-text-secondary-active-background: rgba(255, 255, 255, 0.24);
      }
    }
  }

  .p-dialog-content {
    height: 100%;
  }

  .p-dialog-footer {
    padding: 0;
  }

  &.no-padding {
    --p-dialog-content-padding: 0;
  }

  &.dialog-warning {
    .p-dialog-header {
      color: var(--p-surface-0);
      background: var(--p-yellow-800);
    }
  }

  &.dialog-danger {
    .p-dialog-header {
      color: var(--p-surface-0);
      background: var(--p-red-500);
    }
  }

  &.dialog-success {
    .p-dialog-header {
      color: var(--p-surface-0);
      background: var(--p-success-800);
    }
  }
}
