.card {
  padding: 1rem;
  background-color: var(--p-surface-0);
  margin-bottom: 2rem;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);

  &:last-child {
    margin-bottom: 0;
  }
}
